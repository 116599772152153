@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
  
      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  .splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .splash-screen .loading-animation {
    font-size: 100px;
    margin-top: 10vh;
    position: relative;
    animation: ease-in-out infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
  }
  